.flex {
    display: flex !important;
    align-items: center;
}

.ct-card-tp .jr-card {
    // border: 1px solid #ddd !important;
    background-color: #fff;
}

.center-widget {
    text-align: center !important;
    transition: all 0.2s ease-in-out;

    &:hover {
        -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .05);
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .05);
    }

    h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}

.img-radius {
    border-radius: 4px;
    width: 100%;
    height: auto;
    margin-bottom: 10px !important;
    margin-bottom: 10px !important;
    min-height: 170px;
}

.ct-btn-v {
    margin-top: 6px;

    button {
        border-radius: 4px !important;
    }
}

.align-center {
    align-items: center !important;
}

.justify-center {
    justify-content: center !important;
}

.custom-card {
    background-color: #f8f8f8;
    border: 1px solid #ddd !important;

    .card-body {
        padding: 0 !important;
    }
}

.custom-editor {
    .rdw-editor-main {
        background-color: #fff !important;
    }
}

.ct-textarea {
    width: 100%;
}

.ct-sec-liet-check {
    padding: 4px 10px;
    border-radius: 4px;
    margin-bottom: 2px;
    width: 100%;
    background-color: #f8f8f8;
}

.dark-p {
    font-weight: 700;
}

.txt-info {
    margin-right: 10px;
}

.box-complete-step {
    padding: 50px 20px;
    background-color: #f8f8f8;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.box-complete {
    padding: 50px 20px;
    background-color: #f8f8f8;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.box-complete-pay {
    padding: 50px 20px;
    background-color: #f8f8f8;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.txt-info {
    font-weight: 700;
}

.bx-side {
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 20px;

    .bc-block h5 {
        display: block;
        width: 100%;
    }
}

.credit-form {
    margin-top: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;

    .card-sec {
        padding: 40px 0;
        background-color: #e7e7e7;
    }
}

.card-app {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    padding: 20px;
    background-color: #fff;
    max-width: 530px;
}

#field-wrapper {
    width: 100%;
    height: 80px;
    font-size: 20px;
    border: 1px solid #ddd;

    & img {
        height: 1.5em;
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 20px;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 20px;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 20px;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        font-size: 20px;
    }
}

.credit-input {
    display: block;
    margin-top: 10px;
}

.page-heading-ct {
    margin-bottom: 20px;

    & h2 {
        font-weight: 700;
        font-size: 18px;
    }
}

.jr-card {
    box-shadow: none;
}

.card-show-list {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px !important;
    border-radius: 4px;
}

.dropZoneCSS>div {
    align-items: center;
    display: flex;
    justify-content: center;

    & p {
        font-size: 16px;
    }
}

.m-r-10 {
    margin-right: 10px !important;
}

.MuiPopover-root {
    z-index: 10000 !important;
}

.get-code {
    width: 100%;
    /* min-height: 200px; */
    background-color: #e7e7e7;
    padding: 24px;
}

.m-t-40 {
    margin-top: 40px;
}

.jr-transparent-card {
    background-color: transparent;
    padding: 10px 0;
}

.ct-md-bx-cp>div>div {
    display: flex;
    align-items: center;
    width: 100%;
}

.cp-btn {
    margin-left: auto !important;
}

.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #829fff;
    border-radius: 4px;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.white-back {
    background-color: #fff;
}

.ct-collapse {
    padding-bottom: 10px !important;
}

.ct-collapse .Collapsible {
    width: calc(100% - 30px);
}

.ct-collapse .Collapsible__trigger {
    cursor: pointer;
    background-color: transparent;
    color: #000000;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 16px;

    &:after {
        top: 0;
    }
}

.link-anchor {
    & a {
        padding: 0 10px;
        cursor: pointer;
        text-decoration: none;
    }
}

.save-box {
    z-index: 10;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    left: 0;
    right: 0;
    background-color: #e7e7e7;
    padding: 10px;
    display: flex;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    & button {
        z-index: 10;
        margin-left: auto;
        margin-right: 20px;
    }
}

.centerd-justify {
    flex-direction: row;
    justify-content: space-between;
}


.custom-cl {
    background-color: #f8f8f8;
}

.custom-cl .MuiCollapse-container {
    padding: 30px 20px !important;
}

.label-text {
    color: #000;
    margin-bottom: 0;
}

.link-container {
    display: flex;
    flex-direction: row;
}

.ct-Pending {
    padding: 4px 6px;
    background-color: #FFCA28;
    color: #fff;
    border-radius: 4px;
}

.ct-Confirmed {
    padding: 4px 6px;
    background-color: #12947f;
    color: #fff;
    border-radius: 4px;
}

.ct-Cancelled {
    padding: 4px 6px;
    background-color: #e84a5f;
    color: #fff;
    border-radius: 4px;
}

.filter-sec {
    background-color: #f8f8f8;
    border-radius: 4px;
    padding: 20px;
}

.section-small {
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 2px;
}

.section-checkout {
    padding: 20px 10px;
    background-color: #f8f8f8;
    border-radius: 2px;
}


.pagination {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #f8f8f8;
}

.pagination>li>a,
.pagination>li>span {
    font-size: .9rem !important;
    color: #212529 !important;
    background-color: transparent !important;
    border: 0 !important;
    outline: 0 !important;
    -webkit-transition: all .3s linear !important;
    transition: all .3s linear !important;

    &:hover {
        background-color: #ddd !important;
    }
}

.pagination>li.active>a {
    background-color: #4285f4 !important;
    color: #fff !important;
    background-color: #4285f4 !important;
    border-radius: .125rem !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-transition: all .2s linear !important;
    transition: all .2s linear !important;
}

.ct-error-bx {
    border: 1px solid #f44336;
    // background-color: rgba(244, 67, 54, 0.5) !important;
}

.DraftEditor-editorContainer {
    padding: 0 20px !important;
}

.centered-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.connecting {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotate {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.border-box-ct {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 4px;
    width: 100%;
}

.MuiDialog-root{
    z-index: 13000 !important;
}

.coupon-code{
    padding: 2px 6px;
    background-color: #3f51b5;
    color: #fff;
    border: 1px dashed #fff;
    display: inline-block;
    border-radius: 20px;
    letter-spacing: 0.5px;
    min-width: 130px;
    text-align: center;
}

.master-creator{
    background-color: #00b7ff65;
}