/*Footer Styles*/

.app-footer {
  padding: 0 32px;
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);
  border-top: 1px solid #ddd;
  background-color: $grey;
  color: $light-gray;
  min-height: $footer-height;
  height: $footer-height;
  max-height: $footer-height;
  & span{
    margin-left: auto;
  }

  .jr-link {
    color: $sidebar-text-color;
    &:hover {
      color: $sidebar-hover-color;
    }
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    padding: 0 20px;
  }
}